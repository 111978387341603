import filter from 'lodash/filter';
import isError from 'lodash/isError';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import startsWith from 'lodash/startsWith';
import values from 'lodash/values';
import raven from 'raven-js';
import { combineEpics } from 'redux-observable';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';

import config from '../config';

const epics =
  ({ history, middlewares }: any) =>
  (action$: any, store: any) => {
    let nbOfErrors = 0;

    // @ts-expect-error ts-migrate(6133) FIXME: 'fn' is declared but its value is never read.
    const epicsMiddlewares = filter(middlewares, (fn, name) =>
      startsWith(name, 'epic'),
    );

    // can create lazy epic like this: epic$.next(asyncEpic1);
    const epic$ = new Rx.BehaviorSubject(
      combineEpics(...values(epicsMiddlewares)),
    );

    return epic$.mergeMap((epic) =>
      epic(action$, store, history, epic$).catch((e: any) => {
        console.error('Error uncaught: ', e); // eslint-disable-line no-console
        nbOfErrors += 1;

        if (nbOfErrors <= 2 && config.sentryEnabled) {
          let error = e;
          if (isPlainObject(error) || isString(error)) {
            const stringifiedError = JSON.stringify(error);
            if (isError(error.error)) {
              const deepError = error.error;
              error = deepError;
            } else {
              error = new Error(stringifiedError);
            }
            error.extraInfo = stringifiedError;
          }
          raven.captureException(error, {
            extra: {
              url: window.location.href,
              extraInfo: error.extraInfo,
            },
          });
        }

        return Rx.Observable.of({ type: 'ERROR_UNCAUGHT', e }).concat(
          epic(action$, store, history, epic$),
        );
      }),
    );
  };

export default epics;
