/**
 * This file contains hardcoded data that is used to populate the prompt for AI Incorrect Submissions.
 *
 * After the experimentation phase is over, we will fully implement the backend for this feature so it's available for all courses and remove this file.
 */

export const technologyVersions: Record<number, string> = {
  735: 'Python 3.9',
  799: 'Python 3.9, pandas 1.3.4, numpy 1.19.5, matplotlib 3.4.3',
  22066: 'Python 3.9, pandas 1.3.4, numpy 1.19.5, matplotlib 3.4.3',
  28314:
    'Python 3.9, pandas 1.3.4, numpy 1.19.5, matplotlib 3.4.3, scikit-learn 1.0',
  25412:
    'Python 3.0, pandas 1.3.4, numpy 1.19.5, matplotlib 3.4.3, shiny-server 1.5.17.973',
  1532: 'Python 3.9, pandas 1.3.4, numpy 1.19.5, matplotlib 3.4.3',
  22639: 'Python 3.9, pandas 1.3.4, numpy 1.19.5, matplotlib 3.4.3',
  58: 'R 4.1.2',
  672: 'R 4.1.2',
  4914: 'R 4.1.2, tidyverse 1.3.1',
  20822: 'R 4.1.2, tidyverse 1.3.1, ggplot2 3.4.2',
  29302: 'PostgreSQL 14',
  29303: 'PostgreSQL 14',
  29304: 'PostgreSQL 14',
  13367: 'PostgreSQL 14',
};

export const sqlTableDefinitions: Record<number, string> = {
  29302: `
CREATE TABLE books(
  id INTEGER PRIMARY KEY,
  title VARCHAR,
  author VARCHAR,
  year INT,
  genre VARCHAR
);`,
  29303: `
CREATE TABLE films (
  id                    INTEGER     PRIMARY KEY,
  title                 VARCHAR,
  release_year          INTEGER,
  country               VARCHAR,
  duration              INTEGER,
  language              VARCHAR,
  certification         VARCHAR,
  gross                 BIGINT,
  budget                BIGINT
);
CREATE TABLE people (
  id                    INTEGER     PRIMARY KEY,
  name                  VARCHAR,
  birthdate             DATE,
  deathdate             DATE
);
CREATE TABLE reviews (
  id                    INTEGER     PRIMARY KEY,
  film_id               INTEGER,
  num_user              INTEGER,
  num_critic            INTEGER,
  imdb_score            REAL,
  num_votes             INTEGER,
  facebook_likes        INTEGER
);
CREATE TABLE roles (
  id                    INTEGER     PRIMARY KEY,
  film_id               INTEGER,
  person_id             INTEGER,
  role                  VARCHAR
);`,
  29304: `
CREATE TABLE cities (
  name VARCHAR PRIMARY KEY,
  country_code VARCHAR,
  city_proper_pop REAL,
  metroarea_pop REAL,
  urbanarea_pop REAL
);
CREATE TABLE countries (
  code VARCHAR PRIMARY KEY,
  name VARCHAR,
  continent VARCHAR,
  region VARCHAR,
  surface_area REAL,
  indep_year INTEGER,
  local_name VARCHAR,
  gov_form VARCHAR,
  capital VARCHAR,
  cap_long REAL,
  cap_lat REAL
);
CREATE TABLE languages (
  lang_id INTEGER PRIMARY KEY,
  code VARCHAR,
  name VARCHAR,
  percent REAL,
  official BOOLEAN
);
CREATE TABLE economies (
  econ_id INTEGER PRIMARY KEY,
  code VARCHAR,
  year INTEGER,
  income_group VARCHAR,
  gdp_percapita REAL,
  gross_savings REAL,
  inflation_rate REAL,
  total_investment REAL,
  unemployment_rate REAL,
  exports REAL,
  imports REAL
);
CREATE TABLE currencies (
  curr_id INTEGER PRIMARY KEY,
  code VARCHAR,
  basic_unit VARCHAR,
  curr_code VARCHAR,
  frac_unit VARCHAR,
  frac_perbasic REAL
);
CREATE TABLE populations (
  pop_id INTEGER PRIMARY KEY,
  country_code VARCHAR,
  year INTEGER,
  fertility_rate REAL,
  life_expectancy REAL,
  size REAL
);
CREATE TABLE economies2015 (
  code VARCHAR PRIMARY KEY,
  year INTEGER,
  income_group VARCHAR,
  gross_savings REAL
CREATE TABLE economies2019 (
  code VARCHAR PRIMARY KEY,
  year INTEGER,
  income_group VARCHAR,
  gross_savings REAL
);
CREATE TABLE eu_countries (code VARCHAR PRIMARY KEY, name VARCHAR);`,
  13367: `
CREATE TABLE country(
  id INT PRIMARY KEY,
  name TEXT
);
CREATE TABLE league(
  id INT PRIMARY KEY,
  country_id INT,
  name TEXT
);
CREATE TABLE team(
  id INT PRIMARY KEY,
  team_api_id INT,
  team_long_name TEXT,
  team_short_name TEXT
);
CREATE TABLE match(
  id INT PRIMARY KEY,
  country_id INT,
  season TEXT,
  stage INT,
  date DATE,
  hometeam_id INT,
  awayteam_id INT,
  home_goal INT,
  away_goal INT
);
  `,
};
