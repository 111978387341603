import { tokens } from '@datacamp/waffles/tokens';
import { useEffect, useState } from 'react';

import config from '../config';

const { breakpoints } = tokens;

export const useIsMobileScreen = () => {
  const isDesktopMediaQuery: MediaQueryList = !config.isServerSideRendering
    ? window.matchMedia(`(min-width: ${breakpoints.medium})`)
    : ({
        matches: false,
      } as MediaQueryList);
  const [isMobile, setIsMobile] = useState(!isDesktopMediaQuery.matches);

  useEffect(() => {
    const onEvent = (event: MediaQueryListEvent) => {
      setIsMobile(!event.matches);
    };

    if (!config.isServerSideRendering) {
      isDesktopMediaQuery.addEventListener('change', onEvent);
      return () => isDesktopMediaQuery.removeEventListener('change', onEvent);
    }

    return () => {};
  }, [isDesktopMediaQuery]);

  return isMobile;
};
