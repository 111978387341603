import { Map as hashMap } from 'immutable';

export const activateTab = (activeKey: any) => (tabs: any) => {
  return tabs.map((value: any, key: any) =>
    value.setIn(['props', 'active'], key === activeKey),
  );
};

export const addTab =
  (tab: { key: string; title: string }) =>
  (props = hashMap()) =>
    props.set(tab.key, hashMap({ title: tab.title }));
