import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import qs from 'qs';
import { connect } from 'react-redux';

import App from '../components/App';
import * as actions from '../redux/actions';
import type { PreFetchedDataState } from '../redux/reducers/preFetchedData';
import * as selectors from '../redux/selectors';

const VALID_QUERY_PARAMETERS = [
  'ex',
  'runtime_config',
  'image',
  'course_id',
  'shared_image',
  'noFocus',
  'skip_lti_check',
  'no_ssr',
  'ff',
  'forced_experiments',
  'learningMode',
  'programming_language',
];

const mapStateToProps = (state: any) => {
  return {
    courseId: selectors.selectCourse(state).get('id'),
    progressIndicatorVisible: selectors.selectProgressIndicatorVisible(state),
    uiTheme: state.getIn(['settings', 'uiTheme']),
    isPreBooted: selectors.selectIsApplicationPreBooted(state),
    bootError: state.getIn(['boot', 'error']),
    headProps: selectors.selectHeadAttributes(state),
    learningRecap: selectors.selectLearningRecap(state),
    preFetchedData: selectors.selectPreFetchedData(state),
    isApplicationBootable: selectors.selectIsApplicationBootable(state),
    isUserEnabledForLearningRecap:
      selectors.selectUserIsEnabledForLearningRecap(state),
    userId: selectors.selectUserSettings(state).get('id'),
  };
};

const mapDispatchToProps = (dispatch: any, { location, match }: any) => ({
  showLearningRecapModal: () => {
    dispatch(
      actions.showModal({
        modal: selectors.MODAL_TYPE.SHOW_LEARN_RECAP,
      }),
    );
  },
  showShowOrSkipRecapModal: () => {
    dispatch(
      actions.showModal({
        modal: selectors.MODAL_TYPE.SHOW_OR_SKIP_RECAP,
      }),
    );
  },
  onBoot({
    authorizationHeader,
    isApplicationBootable,
    language,
    preFetchedData,
  }: {
    authorizationHeader: string | undefined;
    isApplicationBootable: boolean;
    language: string;
    preFetchedData: PreFetchedDataState;
  }) {
    if (!isApplicationBootable) {
      return;
    }

    const params = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as Record<string, string>;
    dispatch(
      actions.boot({
        authorizationHeader,
        courseRef: match.params.courseRef,
        chapterRef: match.params.chapterRef,
        language,
        isPreview: match.params.previewMode === 'preview',
        queryParams: omitBy(pick(params, VALID_QUERY_PARAMETERS), isEmpty),
        originalLocation: {
          url: `${location.pathname}${location.search}`,
          pathname: location.pathname,
          params,
        },
        preFetchedData,
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
