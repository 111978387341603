import cx from 'classnames';
import React from 'react';

import hcCollapsePanel from '../HighOrderComponents/hcCollapsePanel';
import Reconnecter from '../Reconnecter';

import SqlErrorPane from './SqlErrorPane';
import SqlQueryMessage from './SqlQueryMessage';
import SqlTotalRows from './SqlTotalRows';
import Table from './Table';

type OwnProps = {
  closeErrorPane: (...args: any[]) => any;
  glContainer?: unknown;
  id: number;
  isClosed?: boolean;
  language: string;
  onSubmitCode: (...args: any[]) => any;
  pre_exercise_code?: string;
  refreshTable: (...args: any[]) => any;
  sct?: string;
  setClosed?: (...args: any[]) => any;
  solution?: string;
  sqlOptions: unknown;
  // import ImmutablePropTypes from 'react-immutable-proptypes';
  // TODO: ImmutablePropTypes.contains({ ...Table.propTypes, isNotView: PropTypes.bool, error: ImmutablePropTypes.contains({}), })
  tab: any;
  tabTitle?: string;
  type?: string;
};

const defaultProps = {
  isClosed: false,
};

type Props = OwnProps & typeof defaultProps;

class TableHandler extends React.Component<Props> {
  static defaultProps = defaultProps;

  canRefreshTable: any;

  constructor(props: Props) {
    super(props);
    this.canRefreshTable = true;
  }

  componentDidMount() {
    this.fetchTable();
  }

  componentDidUpdate() {
    this.fetchTable();
  }

  componentWillUpdate(nextProps: Props) {
    if (
      nextProps.tab != null &&
      nextProps.tab.get('active') !== this.props.tab.get('active')
    ) {
      this.canRefreshTable = true;
    }
  }

  fetchTable() {
    const {
      id,
      language,
      pre_exercise_code: preExerciseCode,
      refreshTable,
      sct,
      solution,
      tab,
      tabTitle,
      type,
    } = this.props;
    if (
      tab != null &&
      !tab.get('isNotView') &&
      tabTitle &&
      tab.get('active') &&
      this.canRefreshTable
    ) {
      this.canRefreshTable = false;
      refreshTable({
        language,
        solution,
        sct,
        type,
        command: 'view',
        pre_exercise_code: preExerciseCode,
        id,
        tableName: tabTitle,
      });
    }
  }

  render() {
    const { closeErrorPane, onSubmitCode, sqlOptions, tab, tabTitle } =
      this.props;

    if (tab == null) {
      return null;
    }

    const isSqlQueryTab = tabTitle === 'query result';
    const records = tab.getIn(['table', 'records']);
    const message = tab.get('message');
    return (
      <div className={cx('flex-container console-sql-container')}>
        {message ? (
          <SqlQueryMessage message={message} />
        ) : (
          <Table table={tab.get('table')} />
        )}
        {tab.get('active') && <Reconnecter />}
        {isSqlQueryTab && (
          <SqlErrorPane
            error={tab.get('error')}
            closeErrorPane={closeErrorPane}
          />
        )}
        {/* @ts-expect-error ts-migrate(2786) FIXME: Type 'false' is not assignable to type 'Element | ... Remove this comment to see the full error message */}
        <SqlTotalRows
          {...{
            onSubmitCode,
            sqlOptions,
            current: (records && records.size) || 0,
            total: tab.getIn(['table', 'total']) || 0,
          }}
        />
      </div>
    );
  }
}

export default hcCollapsePanel({ fromTopToBottom: true, closedBarHeight: 33 })(
  TableHandler,
);
