import { ErrorBoundary } from '@datacamp/le-shared-components';
import { Dialog } from '@datacamp/waffles/dialog';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import raven from 'raven-js';
import React, { Suspense, useCallback } from 'react';

import ChapterRating from '../components/ChapterRating';
import DCSpinner from '../components/DCSpinner';
import PeekVideo from '../components/PeekVideo';
import config from '../config';
import { useDispatch, useSelector } from '../interfaces/State';
import unreachable from '../interfaces/unreachable';
import * as actions from '../redux/actions';
import type { ModalSettingsShape } from '../redux/reducers/settings';
import * as selectors from '../redux/selectors';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

type GetModalHandlerArgs = {
  defaultOnExit: () => void;
  modalSettings: ModalSettingsShape;
};
const { NO_MODAL, WAFFLES_DIALOG } = selectors.MODAL_COMPONENT_TYPE;
type ModalViewConfig = {
  Content: React.ReactElement;
  modalComponentType: selectors.ModalComponentType;
  onExit?: () => void;
} & (
  | {
      dialogProps?: Partial<React.ComponentProps<typeof Dialog> & { css: any }>;
      isWideModal?: boolean;
      modalComponentType: typeof WAFFLES_DIALOG;
    }
  | {
      dialogProps?: undefined;
      modalComponentType: typeof NO_MODAL;
    }
);

const GuidesModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modal-views" */ '../components/Guides/GuidesModal'
    ),
);
const DownloadDatasetsModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modal-views" */ '../components/Guides/DownloadDatasetsModal'
    ),
);
const MakeScreenLargerModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modal-views" */ '../components/Guides/MakeScreenLargerModal'
    ),
);
const InteractingWithFloatingPaneModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modal-views" */ '../components/Guides/InteractingWithFloatingPaneModal'
    ),
);
const ReferenceMaterialModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modal-views" */ '../components/Guides/ReferenceMaterialModal'
    ),
);
const LoadingWorkbooksModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modal-views" */ '../components/Guides/LoadingWorkbooksModal'
    ),
);
const IssueReporter = React.lazy(
  () => import(/* webpackChunkName: "modal-views" */ './IssueReporter'),
);
const FeedbackReporter = React.lazy(
  () => import(/* webpackChunkName: "modal-views" */ './FeedbackReporter'),
);
const SignupForm = React.lazy(
  () =>
    import(/* webpackChunkName: "modal-views" */ '../components/SignupForm'),
);
const Slides = React.lazy(
  () => import(/* webpackChunkName: "modal-views" */ './Slides/ModalSlides'),
);
const CourseNotesModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modal-views" */ '../components/CourseNotes/CourseNotesModal'
    ),
);
const IframeModal = React.lazy(
  () =>
    import(/* webpackChunkName: "modal-views" */ '../components/IframeModal'),
);
const CompletionPaneShortcutsModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modal-views" */ '../components/CompletionPaneShortcutsModal'
    ),
);
const LtiFailure = React.lazy(
  () =>
    import(/* webpackChunkName: "modal-views" */ '../components/LtiFailure'),
);
const MobilePopupModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modal-views" */ '../components/MobilePopup/MobilePopupModal'
    ),
);
const BackToDashboardSurvey = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modal-views" */ '../components/BackToDashboardSurvey'
    ),
);
const ShowLearningRecap = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modal-views" */ '../components/ShowLearningRecap/ShowLearningRecap'
    ),
);
const ShowOrSkipRecap = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modal-views" */ '../components/ShowOrSkipRecap'
    ),
);
const ArchivedResourceModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modal-views" */ '../components/ContentAuthorizationService/ArchivedResourceModal'
    ),
);

const getModalViewConfig = ({
  defaultOnExit,
  modalSettings,
}: GetModalHandlerArgs): ModalViewConfig | null => {
  switch (modalSettings.code) {
    case selectors.MODAL_TYPE.COURSE_OUTLINE.code:
      // handled elsewhere so we can add the content to the dom but not show it for seo
      return null;
    case selectors.MODAL_TYPE.GUIDES.code:
      return {
        Content: <GuidesModal />,
        modalComponentType: WAFFLES_DIALOG,
      };
    case selectors.MODAL_TYPE.DOWNLOAD_DATASETS.code:
      return {
        Content: <DownloadDatasetsModal />,
        modalComponentType: WAFFLES_DIALOG,
      };
    case selectors.MODAL_TYPE.SCREEN_LARGER.code:
      return {
        Content: <MakeScreenLargerModal />,
        modalComponentType: WAFFLES_DIALOG,
      };
    case selectors.MODAL_TYPE.INTERACT_WITH_FLOATING_PANE.code:
      return {
        Content: <InteractingWithFloatingPaneModal />,
        modalComponentType: WAFFLES_DIALOG,
      };
    case selectors.MODAL_TYPE.REFERENCE_MATERIAL.code:
      return {
        Content: <ReferenceMaterialModal />,
        modalComponentType: WAFFLES_DIALOG,
      };
    case selectors.MODAL_TYPE.LOADING_WORKBOOKS.code:
      return {
        Content: <LoadingWorkbooksModal />,
        modalComponentType: WAFFLES_DIALOG,
      };
    case selectors.MODAL_TYPE.ISSUE_REPORTER.code: {
      return {
        Content: (
          <IssueReporter
            // @ts-expect-error ModalType's are not designed with params in mind :(
            submitRequestState={modalSettings.submitRequestState || 'none'}
            onExit={defaultOnExit}
          />
        ),
        modalComponentType: WAFFLES_DIALOG,
      };
    }
    case selectors.MODAL_TYPE.FEEDBACK_REPORTER.code: {
      return {
        Content: (
          <FeedbackReporter
            // @ts-expect-error ModalType's are not designed with params in mind :(
            submitRequestState={modalSettings.submitRequestState || 'none'}
            onExit={defaultOnExit}
          />
        ),
        modalComponentType: WAFFLES_DIALOG,
      };
    }
    case selectors.MODAL_TYPE.LOGIN.code:
      return {
        Content: <SignupForm datacampUrl={config.urls.datacamp} />,
        modalComponentType: WAFFLES_DIALOG,
        dialogProps: { alignCenter: true, closeButtonOverride: <></> },
      };
    case selectors.MODAL_TYPE.SLIDES.code:
      return {
        modalComponentType: WAFFLES_DIALOG,
        Content: <Slides />,
        isWideModal: true,
      };
    case selectors.MODAL_TYPE.COURSE_NOTES.code:
      return {
        modalComponentType: WAFFLES_DIALOG,
        Content: <CourseNotesModal />,
        dialogProps: {
          alignCenter: true,
          css: {
            width: '100%',
            maxHeight: 800,
          },
        },
      };
    case selectors.MODAL_TYPE.IFRAME.code: {
      return {
        modalComponentType: WAFFLES_DIALOG,
        Content: <IframeModal onExit={defaultOnExit} />,
        dialogProps: {
          closeButtonOverride: <></>,
          css: {
            background: 'transparent',
            boxShadow: 'none',
            maxWidth: '100vw',
          },
        },
      };
    }
    case selectors.MODAL_TYPE.KEYBOARD_SHORTCUTS.code:
      return {
        modalComponentType: WAFFLES_DIALOG,
        Content: <CompletionPaneShortcutsModal />,
      };
    case selectors.MODAL_TYPE.LTI_FAILURE.code:
      return {
        modalComponentType: WAFFLES_DIALOG,
        dialogProps: {
          alignCenter: true,
          closeButtonOverride: <></>,
        },
        Content: <LtiFailure />,
      };
    case selectors.MODAL_TYPE.CHAPTER_RATING.code: {
      // @ts-expect-error ModalType's are not designed with params in mind :(
      const { onFinish } = modalSettings;
      return {
        modalComponentType: NO_MODAL,
        Content: <ChapterRating onFinish={onFinish} />,
      };
    }
    case selectors.MODAL_TYPE.MOBILE_POPUP.code:
      return {
        modalComponentType: WAFFLES_DIALOG,
        Content: <MobilePopupModal />,
        dialogProps: {
          alignCenter: true,
        },
      };
    case selectors.MODAL_TYPE.VIDEO.code:
      return {
        modalComponentType: NO_MODAL,
        Content: <PeekVideo />,
      };
    case selectors.MODAL_TYPE.DASHBOARD_SURVEY.code: {
      // @ts-expect-error ModalType's are not designed with params in mind :(
      const { redirectUrl } = modalSettings;
      return {
        modalComponentType: WAFFLES_DIALOG,
        Content: <BackToDashboardSurvey redirectUrl={redirectUrl} />,
        onExit: () => {
          window.location.href = redirectUrl;
        },
      };
    }
    case selectors.MODAL_TYPE.SHOW_LEARN_RECAP.code:
      return {
        modalComponentType: WAFFLES_DIALOG,
        Content: <ShowLearningRecap />,
        dialogProps: { closeButtonOverride: <></> },
        isWideModal: true,
      };
    case selectors.MODAL_TYPE.SHOW_OR_SKIP_RECAP.code:
      return {
        modalComponentType: WAFFLES_DIALOG,
        Content: <ShowOrSkipRecap />,
        dialogProps: { alignCenter: true, closeButtonOverride: <></> },
      };
    case selectors.MODAL_TYPE.ARCHIVED_RESOURCE.code:
      return {
        Content: <ArchivedResourceModal />,
        modalComponentType: WAFFLES_DIALOG,
        dialogProps: {
          closeButtonOverride: <></>,
        },
        onExit: noop,
      };
    default:
      return unreachable(modalSettings.code);
  }
};

const ModalHandler: React.FC<Props> = () => {
  const modalSettingsImmutable = useSelector(selectors.selectModalSettings);
  const modalSettings: ModalSettingsShape = modalSettingsImmutable.toJS();

  const dispatch = useDispatch();
  const onExit = useCallback(() => {
    dispatch(actions.removeModal());
  }, [dispatch]);

  if (isEmpty(modalSettings) || !modalSettings.show) {
    return null;
  }
  const modalViewConfig = getModalViewConfig({
    defaultOnExit: modalSettings.onExit || onExit,
    modalSettings,
  });
  if (modalViewConfig == null) {
    return null;
  }
  const onExitHooked = modalSettings.onExit || modalViewConfig.onExit || onExit;

  const { Content, dialogProps = {} } = modalViewConfig;

  switch (modalViewConfig.modalComponentType) {
    case NO_MODAL:
      return Content;

    case WAFFLES_DIALOG:
      return (
        <Dialog
          isOpen
          onClose={onExitHooked}
          {...(modalViewConfig.isWideModal && {
            css: { maxWidth: 900, width: '100%' },
          })}
          {...dialogProps}
        >
          <ErrorBoundary onErrorReported={raven.captureException}>
            <Suspense fallback={<DCSpinner />}>{Content}</Suspense>
          </ErrorBoundary>
        </Dialog>
      );

    default:
      return unreachable(modalViewConfig);
  }
};

export default ModalHandler;
