export const topicById = {
  3: 'Data Manipulation',
  4: 'Data Visualization',
  5: 'Reporting',
  6: 'Machine Learning',
  7: 'Probability & Statistics',
  8: 'Importing & Cleaning Data',
  9: 'Applied Finance',
  10: 'Programming',
  11: 'Other',
  12: 'Case Studies',
  13: 'Data Management',
  14: 'Data Engineering',
  16: 'Artificial Intelligence',
  17: 'Data Literacy',
  18: 'Data Preparation',
  19: 'Exploratory Data Analysis',
  20: 'Data Warehouse',
  21: 'Leadership',
  22: 'Cloud',
} as const;

export type TopicId = keyof typeof topicById;

export const topic = Object.values(topicById);
export type Topic = (typeof topic)[number];
