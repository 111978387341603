import { ExerciseEnvironment } from '@datacamp/exercise-contract';
import split from 'lodash/split';

const env = process.env.NODE_ENV;
const ecsEnv = process.env.REACT_APP_ECS_ENV || 'production';
const IS_SSR = process.env.REACT_APP_IS_SSR;
const RELEASE = process.env.REACT_APP_RELEASE;

export default {
  env,
  ecsEnv,
  release: RELEASE,
  isProductionEnv: env === 'production',
  isProductionDomain: () => ecsEnv === 'production',
  getStandaloneExerciseEnvironment: () => {
    switch (ecsEnv) {
      case 'production':
        return ExerciseEnvironment.PRODUCTION;
      case 'staging':
        return ExerciseEnvironment.STAGING;
      case 'development':
      default:
        return ExerciseEnvironment.DEVELOPMENT;
    }
  },
  isServerSideRendering: IS_SSR === 'true',
  serveStaticFiles: process.env.SERVE_STATIC_FILES === 'true',
  isMathjaxDeactivated: process.env.REACT_APP_DEACTIVATE_MATHJAX === 'true',
  sentryEnabled: process.env.REACT_APP_SENTRY_DISABLED !== 'true',
  allowedOrigins: split(process.env.ALLOWED_ORIGINS, ','),
  tracerServiceName: process.env.TRACER_SERVICE_NAME,
  tracerEnabled: process.env.TRACER_ENABLED === 'true',
  tracerHostname: process.env.TRACER_HOSTNAME,
  tracerPort: process.env.TRACER_PORT,
  appName: 'campus-app',
  postMessageTargetOrigin:
    process.env.REACT_APP_POST_MESSAGE_TARGET_ORIGIN || '*',

  serviceUrls: ((e: string) => {
    switch (e) {
      case 'dev':
      case 'development':
      case 'staging':
        return {
          aiApi:
            process.env.AI_API_INTERNAL_URL ||
            'https://ai-api.datacamp-staging.com',

          assets:
            process.env.ASSETS_INTERNAL_URL || 'https://assets.datacamp.com',

          campusApi:
            process.env.CAMPUS_API_INTERNAL_URL ||
            'https://campus-api.datacamp-staging.com',

          collab:
            process.env.COLLAB_API_INTERNAL_URL ||
            'https://collab-api.datacamp-staging.com',

          groupHubApi:
            process.env.GROUP_HUB_API_INTERNAL_URL ||
            'https://www.datacamp-staging.com/groups/graphql',

          imb:
            process.env.IMB_INTERNAL_URL || 'https://imb.datacamp-staging.com',

          learnDataWarehouse:
            process.env.LEARN_DATA_WAREHOUSE_INTERNAL_URL ||
            'https://learn-datawarehouse-api.datacamp-staging.com',

          mainApp:
            process.env.MAIN_APP_INTERNAL_URL ||
            'https://www.datacamp-staging.com',

          mobile:
            process.env.MOBILE_API_INTERNAL_URL ||
            'https://mobile-api.datacamp-staging.com',

          teach:
            process.env.TEACH_INTERNAL_URL ||
            'https://teach-api.datacamp-staging.com/teach-app',

          cms:
            process.env.CMS_INTERNAL_URL || 'https://cms.datacamp-staging.com',

          cas:
            process.env.CAS_INTERNAL_URL ||
            'https://content-authorization.datacamp-staging.com',
        };
      default:
        return {
          aiApi:
            process.env.AI_API_INTERNAL_URL || 'https://ai-api.datacamp.com',

          assets:
            process.env.ASSETS_INTERNAL_URL || 'https://assets.datacamp.com',

          campusApi:
            process.env.CAMPUS_API_INTERNAL_URL ||
            'https://campus-api.datacamp.com',

          collab:
            process.env.COLLAB_API_INTERNAL_URL ||
            'https://collab-api.datacamp.com',

          groupHubApi:
            process.env.GROUP_HUB_API_INTERNAL_URL ||
            'https://www.datacamp.com/groups/graphql',

          imb: process.env.IMB_INTERNAL_URL || 'https://imb.datacamp.com',

          learnDataWarehouse:
            process.env.LEARN_DATA_WAREHOUSE_INTERNAL_URL ||
            'https://learn-datawarehouse-api.datacamp.com',

          mainApp:
            process.env.MAIN_APP_INTERNAL_URL || 'https://www.datacamp.com',

          mobile:
            process.env.MOBILE_API_INTERNAL_URL ||
            'https://mobile-api.datacamp.com',

          teach:
            process.env.TEACH_INTERNAL_URL ||
            'https://teach-api.datacamp.com/teach-app',

          cms: process.env.CMS_INTERNAL_URL || 'https://cms.datacamp.com',

          cas:
            process.env.CAS_INTERNAL_URL ||
            'https://content-authorization.datacamp.com',
        };
    }
  })(ecsEnv),

  urls: ((e) => {
    switch (e) {
      case 'dev':
      case 'development':
      case 'staging':
        return {
          workspace: 'https://app.datacamp-staging.com/workspace',
          datalab: 'https://www.datacamp-staging.com/datalab',
          datacamp: 'https://www.datacamp-staging.com',
          campusApi: 'https://campus-api.datacamp-staging.com',
          multiplexer: 'https://sessions.datacamp-staging.com',
          rdoc: 'https://staging.rdocumentation.org',
          timetracker: 'https://timetracker.datacamp-staging.com',
          trackingGenerator: 'https://compliance.datacamp-staging.com',
          assets: 'https://assets.datacamp.com',
          projector: 'https://projector.datacamp-staging.com',
          googleSheets: 'https://spreadsheets.datacamp-staging.com',
          prometheusAggregator:
            'https://prometheus-aggregator.datacamp-staging.com',
          cas: 'https://content-authorization.datacamp-staging.com',
          learn: 'https://app.datacamp-staging.com/learn',
          learnWhatsNextApi:
            'https://learn-whats-next-api.datacamp-staging.com',
        };
      default:
        return {
          workspace: 'https://app.datacamp.com/workspace',
          datalab: 'https://www.datacamp.com/datalab',
          datacamp: 'https://www.datacamp.com',
          campusApi: 'https://campus-api.datacamp.com',
          multiplexer: 'https://sessions.datacamp.com',
          betaMultiplexer: 'https://multiplexer-beta.datacamp.com',
          rdoc: 'https://www.rdocumentation.org',
          timetracker: 'https://timetracker.datacamp.com',
          trackingGenerator: 'https://compliance.datacamp.com',
          assets: 'https://assets.datacamp.com',
          projector: 'https://projector.datacamp.com',
          googleSheets: 'https://spreadsheets.datacamp.com',
          prometheusAggregator: 'https://prometheus-aggregator.datacamp.com',
          cas: 'https://content-authorization.datacamp.com',
          learn: 'https://app.datacamp.com/learn',
          learnWhatsNextApi: 'https://learn-whats-next-api.datacamp.com',
        };
    }
  })(ecsEnv),
};
