import type Multiplexer from '@datacamp/multiplexer-client';

let codeExecutionBackend: Multiplexer.AsyncSession | undefined;

type CodeExecutionBackend = Multiplexer.AsyncSession;

export const getCodeExecutionBackend = (): CodeExecutionBackend | undefined => {
  return codeExecutionBackend;
};

export const setCodeExecutionBackend = (
  newCodeExecutionBackend: CodeExecutionBackend,
): void => {
  codeExecutionBackend = newCodeExecutionBackend;
};
